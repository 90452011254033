import { Types } from './types'

const initialState = {
    dataBrand: {
        filter: {},
        rows: []
    },
    currentRecord: null
}
const brandReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_BRAND:
            return {
                ...newState,
                dataBrand: action.payload
            }
        case Types.SET_RECORD:
            return {
                ...newState,
                currentRecord: action.payload
            }
        default:
            return { ...newState }
    }
}
export default brandReducer
