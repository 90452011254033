export const ROLE_SELLER = 'SELLER'

export const ROLE_ADMIN = {
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  SELLER_MANAGER: 'SELLER_MANAGER',
  ADMIN_MANAGER: 'ADMIN_MANAGER',
  CATEGORY_MANAGER: 'CATEGORY_MANAGER',
  PRODUCT_MANAGER: 'PRODUCT_MANAGER',
  BRAND_MANAGER: 'BRAND_MANAGER',
  COMPANY_SHIPPING_MANAGER: 'COMPANY_SHIPPING_MANAGER',
  HOLIDAY_MANAGER: 'HOLIDAY_MANAGER',
  TIMEWORK_MANAGER: 'TIMEWORK_MANAGER',
  ORDER_MANAGER: 'ORDER_MANAGER',
  CSV_ADMIN: 'CSV_ADMIN',
  BILL_MANAGER: 'BILL_MANAGER',
  VOUCHER_MANAGER: 'VOUCHER_MANAGER',
  EDIT_CREATE_SELLER: 'EDIT_CREATE_SELLER',
  EDIT_STATUS_FINISH: 'EDIT_STATUS_FINISH',
  EDIT_WHEN_LOCK_ORDER: 'EDIT_WHEN_LOCK_ORDER',
}

export const DURATION_MESSAGE = 5;

export const STATUS_ORDER = {
  BOOKING: 'booking',
  OPEN: 'open',
  SEND: 'send',
  CHECKING: 'checking',
  DISCUSSION: 'discussion',
  FINISH: 'finish',
  REJECT: 'reject',
  CANCEL: 'cancel',
  PAID: 'paid',
  UNPAID: 'unpaid'
};

export const ACTION_ORDER = {
  PRICE_CHANGE: 'price_change',
  STATUS_CHANGE: 'status_change',
  PAYMENT_CHANGE: 'payment_change'
};

export const TYPE_ORDER = {
  NORMAL: 'normal',
  PRESALE: 'preSale',
  COMBINE: 'combine',
  PENDING: 'pending'
}

export const LIMIT_NUMBER_PAGE = 10;
export const SPLIT_POINT = 30000;

export const TYPE_PRODUCT = {
  NORMAL: 'price',
  PRESALE: 'pre_sale'
}
export const TYPE_PRODUCT_FE = {
  NORMAL_FE: 'normal',
  PRESALE_FE: 'preSale',
}
export const COLOR_TOOLTIP = 'green';
export const LIST_LIMIT = [
  {
    name: '10',
    value: '10'
  },
  {
    name: '20',
    value: '20'
  },
  {
    name: '30',
    value: '30'
  },
  {
    name: '40',
    value: '40'
  },
  {
    name: '50',
    value: '50'
  }
]
export const TYPE_BANK = [
  {
    name: '銀行',
    value: 'bank'
  },
  {
    name: '信用金庫名',
    value: 'credit'
  }
]
export const TYPE_BRANCH = [
  {
    name: '支店',
    value: 'branch'
  },
  {
    name: '出張所名',
    value: 'branchOffice'
  }
]
export const TYPE_NEWS = [
  {
    name: "新規取り扱い商品",
    value: 1
  },
  {
    name: "取引ルールの変更",
    value: 2
  },
  {
    name: "価格変更履歴",
    value: 3
  },
  {
    name: "重要事項",
    value: 4
  }
]

export const STATUS_NEWS = [
  {
    name: "全てのニュース",
    value: 2
  },
  {
    name: "ログインユーザーのみ、表示するニュース",
    value: 0
  },
  {
    name: "未ログインユーザーへも表示するニュース",
    value: 1
  }
]