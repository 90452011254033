import { Types } from './types'

const initialState = {
    dataUser: {
        filter: {},
        rows: []
    },
    dataOrderOfUser: {
        filter: {},
        rows: []
    },
    detailSeller: {
    },
    currentRecord: null
}
const userReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_USER:
            return {
                ...newState,
                dataUser: action.payload
            }
        case Types.SAVE_LIST_ORDER_USER:
            return {
                ...newState,
                dataOrderOfUser: action.payload
            }
        case Types.SET_RECORD:
            return {
                ...newState,
                currentRecord: action.payload
            }
        case Types.SAVE_DETAIL_SELLER:
            return {
                ...newState,
                detailSeller: action.payload
            }
        default:
            return { ...newState }
    }
}
export default userReducer