export const Types = {
    SAVE_LIST_ORDER: 'SAVE_LIST_ORDER',
    SAVE_RECORD_MULTI_ORDER: 'SAVE_RECORD_MULTI_ORDER',
    SAVE_DETAIL_ORDER: 'SAVE_DETAIL_ORDER',
    SAVE_LIST_ORDER_COMBINE: 'SAVE_LIST_ORDER_COMBINE',
    SAVE_LIST_ORDER_PRE_SALE: 'SAVE_LIST_ORDER_PRE_SALE',
    SAVE_LIST_ORDER_NOMAL: 'SAVE_LIST_ORDER_NOMAL',
    HAVE_CHANGE: 'HAVE_CHANGE',
    HAVE_CHANGE_PRICE: 'HAVE_CHANGE_PRICE',
    UPDATE_LIST_PRODUCT: 'UPDATE_LIST_PRODUCT',
    SAVE_LIST_ORDER_PENDING: 'SAVE_LIST_ORDER_PENDING',
    GET_SUCCESS_LIST_ORDER: 'GET_SUCCESS_LIST_ORDER',
    UPDATE_ORDER_IN_LIST: 'UPDATE_ORDER_IN_LIST',
    HAVE_CHANGE_IN_LIST: 'HAVE_CHANGE_IN_LIST',
    HAVE_CHANGE_PRICE_IN_LIST: 'HAVE_CHANGE_PRICE_IN_LIST',
    HAVE_CHANGE_DATE_RECEIVED: 'HAVE_CHANGE_DATE_RECEIVED',
    HAVE_CHANGE_DATE_RECEIVED_IN_LIST: 'HAVE_CHANGE_DATE_RECEIVED_IN_LIST',
}