import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import authReducer from "./auth/reducer"
import homeReducer from "./home/reducer"
import rolePermissionReducer from "./role_permission/reducer"
import adminManagementReducer from "./admin_management/reducer"
import categoryReducer from './category/reducer'
import brandReducer from './brand/reducer'
import voucherReducer from './voucher/reducer'
import companyReducer from './shipping_company/reducer'
import holidayReducer from './hoiliday/reducer'
import productReducer from './product/reducer'
import rankReducer from './rank/reducer'
import userReducer from "./seller/reducer";
import timeWorkReducer from './time_work/reducer'
import locationReducer from './location/reducer'
import bankReducer from './bank/reducer'
import orderReducer from './order/reducer';
import commentReducer from './comment/reducer';
import billReducer from './bill/reducer';
import orderCSVReducer from './OrderCSV/reducer';
import newsReducer from './news/reducer';
import typeNewsReducer from './type_news/reducer';

export const rootReducer = combineReducers({
  typeNewsReducer,
  orderCSVReducer,
  billReducer,
  auth: auth.reducer,
  authReducer: authReducer,
  homeReducer: homeReducer,
  rolePermissionReducer,
  adminManagementReducer,
  categoryReducer,
  brandReducer,
  voucherReducer,
  companyReducer,
  holidayReducer,
  productReducer,
  rankReducer,
  timeWorkReducer,
  userReducer,
  locationReducer,
  bankReducer,
  orderReducer,
  commentReducer,
  newsReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
