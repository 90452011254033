import { Types } from './types'

const initialState = {
    dataUserAdmin: {
        filter: {},
        rows: []
    },
    detailAdmin: {},
}
const homeReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_USER_ADMIN:
            return {
                ...newState,
                dataUserAdmin: action.payload
            }
        case Types.SAVE_DETAIL_ADMIN:
            return {
                ...newState,
                detailAdmin: action.payload
            }
        default:
            return { ...newState }
    }
}
export default homeReducer
