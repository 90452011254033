import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { message } from 'antd';
import { useLocation } from "react-router-dom";
import { Input, Form } from 'antd';
import { Link } from "react-router-dom";

import { DURATION_MESSAGE } from '../../../../lib/constant';
import * as auth from "../_redux/authRedux";
import { 
  actionResetForgotPassword,
  actionCheckValidLink
} from '../../../../redux/auth/actions';
import _ from "lodash";
import Button from '../../../../components/CustomButton';
import { checkEmoji, passwordChecker } from "../../../../lib/common";

const formRef = React.createRef()

function ConfirmPassword(props) {

  const { history } = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const authenticationString = new URLSearchParams(search).get('authenticationString');

  useEffect(() => {
    checkValidLink();
  }, []);

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSubmit()
    }
  }

  const checkValidLink = async () => {
    const email_param = new URLSearchParams(search).get('email');
    const authenticationString_param = new URLSearchParams(search).get('authenticationString');
    const dataSubmit = {
      email: _.toString(email_param).replace(' ', '+'),
      authenticationString: authenticationString_param,
    }
    const res = await dispatch(actionCheckValidLink(dataSubmit))
    if (res) {
      if(res.code === 'ERR_1017') {
        setTimeout(() => {
          window.alert(intl.formatMessage({ id: "ERR_1017" }));
          history.push("/login");
        }, 1000);
      }
    }
  }

  const onSubmit = async () => {
    await formRef.current.validateFields();
    setLoading(true)
    const { password, passwordRepeat } = formRef.current.getFieldsValue()

    const dataSubmit = {
      password: password.trim(),
      passwordRepeat: passwordRepeat.trim(),
      email:  _.toString(email).replace(' ', '+'),
      authenticationString: authenticationString,
    }

    const res = await dispatch(actionResetForgotPassword(dataSubmit))
    if (res) {
      message.success(intl.formatMessage({ id: "FORGOTPASSWORD.SUCCESS" }), DURATION_MESSAGE);
      return history.push("/login");
    }
    setLoading(false)
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
        <div className="alert-text row-center fs--16 lh--24">
          <strong>  <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3> </strong>
        </div>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Form
        name="customForm"
        ref={formRef}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        <div className="form-group fv-plugins-icon-container">
          <Form.Item
            type="text"
            name="password"
            rules={[
              {
                validator: (_, value) => {
                  const v = value && value.trim();
                  if (!v) {
                    return Promise.reject(intl.formatMessage({ id: "CONFIRMPASSWORD.REQUIRE_PASSWORD" }));
                  }
                  if (v.length < 8) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.REQUIRE_PASSWORD_LENGTH" }));
                  }
                  if(v && checkEmoji(v)) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.INCLUDE_EMOJI" }));
                  }
                  if(!passwordChecker(v)) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.PASSWORD_WRONG_FORMAT" }));
                  } 
                  if ((v.indexOf(' ') >= 0 ) ||  (v.indexOf('  ') > 0) ) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.PASSWORD_INCLUDE_SPACE" }));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input.Password
              maxLength={30}
              className="customInput"
              placeholder={intl.formatMessage({ id: "CONFIRMPASSWORD.PASSWORD_PLACEHOLDER" })}
              prefix={<img src={`${window.location.origin}/images/lock_24px 2.svg`} alt="" />}
              onKeyPress={e => onEnterPress(e)}
            />
          </Form.Item>
        </div>
        <div className="form-group fv-plugins-icon-container mt--13 panel-password">
          <Form.Item
            name="passwordRepeat"
            dependencies={['password']}
            rules={[
              {
                validator: (_, value) => {
                  const v = value && value.trim();
                  if (!v) {
                    return Promise.reject(intl.formatMessage({ id: "CONFIRMPASSWORD.REQUIRE_PASSWORD" }));
                  }
                  return Promise.resolve();
                }
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject();
                  }
                  if(value && checkEmoji(value)) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.INCLUDE_EMOJI" }));
                  }
                  if(value && !passwordChecker(value)) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.PASSWORD_WRONG_FORMAT" }));
                  } 
                  if (value && ((value.indexOf(' ') >= 0 ) ||  (value.indexOf('  ') > 0)) ) {
                    return Promise.reject(intl.formatMessage({ id: "PROFILE.PASSWORD_INCLUDE_SPACE" }));
                  }
                  if (value && getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(intl.formatMessage({ id: "PROFILE.REQUIRE_PASSWORD_MATCH" }));
                },
              }),
            ]}
          >
            <Input.Password
              maxLength={30}
              className="customInput"
              placeholder={intl.formatMessage({ id: "CONFIRMPASSWORD.PASSWORD_REPEATE_PLACEHOLDER" })}
              prefix={<img src={`${window.location.origin}/images/lock_24px 2.svg`} alt="" />}
            />
          </Form.Item>
          <div className="form-group fv-plugins-icon-container mt--13 panel-text-forget-password">
            <Link className="text-d-blue forgotpass-btn fs-16 fw-6 text-right db hover-a text-decoration-underline" to="/login">
              {intl.formatMessage({ id: "AUTH.LOGIN.TITLE" })}
            </Link>
          </div>
        </div>
        <div className="form-group justify-content-between align-items-center " style={{ textAlign: 'center', marginTop: 25 }}>
          <Button
            type='submit'
            className="width100"
            loading={loading}
            title={intl.formatMessage({ id: "CONFIRMPASSWORD.BTN_CONFIRM" })}
            btnColor="#ffffff"
            btnBgColor="#3699FF"
            btnHoverBackground="#187DE4"
            onClick={onSubmit}
          />
        </div>
      </Form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ConfirmPassword));
