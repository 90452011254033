import { Types } from './types'

const initialState = {
  dataOrder: {
    filter: {},
    rows: []
  },
  dataOrderPreSale: {
    filter: {},
    rows: []
  },
  dataOrderNormal: {
    filter: {},
    rows: []
  },
  dataOrderPending: {
    filter: {},
    rows: []
  },
  dataOrderCombine: {
    filter: {},
    rows: []
  },
  recordMultiOrder: [],
  detailOrder: {},
  haveChange: false,
  haveChangePrice: false,
  haveChangeDateReceived: false,
  lstOrderDetail: [],
}
const orderReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case Types.SAVE_LIST_ORDER:
      return {
        ...newState,
        dataOrder: action.payload
      }
    case Types.SAVE_LIST_ORDER_NOMAL:
      return {
        ...newState,
        dataOrderNormal: action.payload
      }
    case Types.SAVE_LIST_ORDER_PRE_SALE:
      return {
        ...newState,
        dataOrderPreSale: action.payload
      }
    case Types.SAVE_LIST_ORDER_COMBINE:
      return {
        ...newState,
        dataOrderCombine: action.payload
      }
    case Types.SAVE_RECORD_MULTI_ORDER:
      return {
        ...newState,
        recordMultiOrder: action.payload
      }
    case Types.SAVE_DETAIL_ORDER:
      return {
        ...newState,
        detailOrder: action.payload
      }
    case Types.HAVE_CHANGE:
      return {
        ...newState,
        haveChange: action.payload
      }
    case Types.HAVE_CHANGE_PRICE:
      return {
        ...newState,
        haveChangePrice: action.payload
      }
    case Types.UPDATE_LIST_PRODUCT:
      return {
        ...newState,
        detailOrder: {
          ...newState.detailOrder,
          products: action.payload
        }
      }
    case Types.UPDATE_DATE_RECEIVED:
      return {
        ...newState,
        detailOrder: {
          ...newState.detailOrder,
          dateReceived: action.payload
        }
      }
    case Types.HAVE_CHANGE_DATE_RECEIVED:
      console.log('3333333333333333333333333333333', newState.haveChangeDateReceived);
      return {
        ...newState,
        haveChangeDateReceived: action.payload
      }
    case Types.SAVE_LIST_ORDER_PENDING:
      return {
        ...newState,
        dataOrderPending: action.payload
      }
    case Types.GET_SUCCESS_LIST_ORDER:
      return {
        ...newState,
        lstOrderDetail: action.payload
      }
    case Types.UPDATE_ORDER_IN_LIST:
      let lstOrder = newState.lstOrderDetail;
      lstOrder = lstOrder.map(item => {
        if (item.id === action.payload.id) {
          item.itemOrder = action.payload;
        }
        return item;
      })
      return {
        ...newState,
        lstOrderDetail: lstOrder
      }
    case Types.HAVE_CHANGE_IN_LIST:
      let lstOrderChange = newState.lstOrderDetail;
      lstOrderChange = lstOrderChange.map(item => {
        if (item.id === action.payload.orderId) {
          item.haveChange = action.payload.haveChange;
        }
        return item;
      })
      return {
        ...newState,
        lstOrderDetail: lstOrderChange
      }
    case Types.HAVE_CHANGE_DATE_RECEIVED_IN_LIST:
      let lstOrderChangeDateReceived = newState.lstOrderDetail;
      lstOrderChangeDateReceived = lstOrderChangeDateReceived.map(item => {
        if (item.id === action.payload.orderId) {
          item.haveChangeDateReceived = action.payload.haveChangeDateReceived;
        }
        return item;
      })
      return {
        ...newState,
        lstOrderDetail: lstOrderChangeDateReceived
      }
    case Types.HAVE_CHANGE_PRICE_IN_LIST:
      let lstOrderChangePrice = newState.lstOrderDetail;
      lstOrderChangePrice = lstOrderChangePrice.map(item => {
        if (item.id === action.payload.orderId) {
          item.haveChangePrice = action.payload.haveChangePrice;
        }
        return item;
      })
      return {
        ...newState,
        lstOrderDetail: lstOrderChangePrice
      }
    default:
      return { ...newState }
  }
}
export default orderReducer