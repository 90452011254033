/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { ROLE_ADMIN } from "../../../../../lib/constant";

function AsideMenuList(props) {

  const { lstPermission } = useSelector(state => ({
    lstPermission: state.authReducer.lstPermission
  }), shallowEqual);

  let { layoutProps, intl } = props
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Dashboard management */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.DASHBOARD' })}</span>
          </NavLink>
        </li> */}


        {/* Admin management */}
        {/*begin::1 Level*/}

        {/* {lstPermission && lstPermission.indexOf(ROLE_ADMIN.ADMIN_MANAGER) >= 0 &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ADMIN_MANAGEMENT' })}</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PERMISSION_LIST' })}</span>
                  </span>
                </li>
          
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/admin/role"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/role">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ROLE_LIST' })}</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        } */}
        {/*end::1 Level*/}

        {/* admin management */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.ADMIN_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive(
              "/list-admin"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/list-admin">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/list-admin") ? toAbsoluteUrl("/media/svg/icons/Icon/Admin-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Admin-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.USER' })}</span>
            </NavLink>
          </li>
        }


        {/* Category management */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.CATEGORY_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/category", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/category">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/category") ? toAbsoluteUrl("/media/svg/icons/Icon/Categoy-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Category-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.CATEGORY' })}</span>
            </NavLink>
          </li>
        }
        {/* Brand management */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.BRAND_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/brand", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/brand">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/brand") ? toAbsoluteUrl("/media/svg/icons/Icon/Brand-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Brand-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.BRAND' })}</span>
            </NavLink>
          </li>
        }

        {/* Voucher management */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.VOUCHER_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/voucher", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/voucher">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/voucher") ? toAbsoluteUrl("/media/svg/icons/Icon/Voucher-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Voucher-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.VOUCHER' })}</span>
            </NavLink>
          </li>
        }
        {/* Shipping Company */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.COMPANY_SHIPPING_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/company", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/company">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/company") ? toAbsoluteUrl("/media/svg/icons/Icon/Company-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Company-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.COMPANY' })}</span>
            </NavLink>
          </li>
        }

        {/* holiday */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.HOLIDAY_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/holiday", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/holiday">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/holiday") ? toAbsoluteUrl("/media/svg/icons/Icon/Holiday-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Holiday-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.HOLIDAY' })}</span>
            </NavLink>
          </li>
        }
        {/* Rank */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/rank", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/rank">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Stairs.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.RANK' })}</span>
          </NavLink>
        </li> */}
        {/* product */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.PRODUCT_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/product", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/product">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/product") ? toAbsoluteUrl("/media/svg/icons/Icon/Product-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Product-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.PRODUCT' })}</span>
            </NavLink>
          </li>
        }

        {/* time work */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.TIMEWORK_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/time-work", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/time-work">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/time-work") ? toAbsoluteUrl("/media/svg/icons/Icon/Time-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Time-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.TIME_WORK' })}</span>
            </NavLink>
          </li>
        }

        {/* fee */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.TIMEWORK_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/fee-setting", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/fee-setting">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/fee-setting") ? toAbsoluteUrl("/media/svg/icons/Icon/ic_coin.svg") : toAbsoluteUrl("/media/svg/icons/Icon/ic_coin_dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.FEE' })}</span>
            </NavLink>
          </li>
        }

        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.SELLER_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/seller", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/seller">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/seller") ? toAbsoluteUrl("/media/svg/icons/Icon/Seller-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Seller-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.SELLER' })}</span>
            </NavLink>
          </li>
        }
        {/* Order */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/order",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/order">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/order") ? toAbsoluteUrl("/media/svg/icons/Icon/Order-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Order-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER' })}</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_NORMAL' })}</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/order/order-all"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/order/order-all">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_ALL' })}</span>
                </NavLink>
              </li> */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/order/order-normal"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order/order-normal">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_NORMAL' })}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/order/order-pre-sale"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order/order-pre-sale">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_PRESALE' })}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/order/order-pending"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/order/order-pending">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_PENDING' })}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/order/order-combine"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/order/order-combine">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.ORDER_COMBINE' })}</span>
                </NavLink>
              </li> */}
              </ul>
            </div>
          </li>
        }

        {/* Comment */}

        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/comment", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/comment">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/comment") ? toAbsoluteUrl("/media/svg/icons/Icon/Comment-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Comment-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.COMMENT' })}</span>
            </NavLink>
          </li>
        }
        {/* build */}
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.BILL_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/bill", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/bill">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/bill") ? toAbsoluteUrl("/media/svg/icons/Icon/Bill-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Bill-dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MANAGE_BILL' })}</span>
            </NavLink>
          </li>
        }
        {lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 &&
          <li
            className={`menu-item ${getMenuItemActive("/export-order", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/export-order">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/export-order") ? toAbsoluteUrl("/media/svg/icons/Icon/Export-light.svg") : toAbsoluteUrl("/media/svg/icons/Icon/Export-dark.svg")} />
              </span>
              <span className="menu-text">注文データ抽出</span>
            </NavLink>
          </li>
        }
        <li
            className={`menu-item ${getMenuItemActive("/news", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/news">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/news") ? toAbsoluteUrl("/media/svg/icons/Icon/ic_news.svg") : toAbsoluteUrl("/media/svg/icons/Icon/ic_news_dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MANAGE_NEWS' })}</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/type-news", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/type-news">
              <span className="svg-icon menu-icon">
                <SVG src={checkIsActive(location, "/type-news") ? toAbsoluteUrl("/media/svg/icons/Icon/ic_news.svg") : toAbsoluteUrl("/media/svg/icons/Icon/ic_news_dark.svg")} />
              </span>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDEBAR.MANAGE_TYPE_NEWS' })}</span>
            </NavLink>
          </li>
      </ul>
    </>
  );
}
export default withRouter(injectIntl(AsideMenuList));
