import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  // eslint-disable-next-line no-unused-vars
  message,
  Input,
  Form
} from 'antd';

import Button from '../../../../components/CustomButton'
import * as auth from "../_redux/authRedux";
import { actionForgotPassword } from '../../../../redux/auth/actions';
import { DURATION_MESSAGE } from '../../../../lib/constant';
import { validateEmailWithoutSpecial, validateEmail } from "../../../../lib/common";

const formRef = createRef();

function ForgotPassword(props) {
  const { intl, history } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const refEmail = useRef(null);

  useEffect(() => {
    if (refEmail.current) {
      refEmail.current.focus();
    }
  }, [refEmail]);

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSubmit()
    }
  }

  // const onSubmit = async () => {
  //   if (!loading) {
  //     return;
  //   }
  //   setLoading(true);
  //   const { email } = formik.current.getFieldsValue();
  //   const dataSubmit = {
  //     email: email.trim().toLowerCase(),
  //   }
  //   const res = await dispatch(actionForgotPassword(dataSubmit));
  //   setLoading(false);
  //   if (res) {
  //     message.success(intl.formatMessage({ id: "FORGOTPASSWORD.SEND_LINK_SUCCESS" }), DURATION_MESSAGE);
  //     return history.push("/login");
  //   }
  // }

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: ForgotPasswordSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     if (loading) {
  //       return;
  //     }
  //     setLoading(true);
  //     const { email } = values;
  //     const dataSubmit = {
  //       email: email.trim().toLowerCase(),
  //     }
  //     dispatch(actionForgotPassword(dataSubmit)).then(res => {
  //       setLoading(false);
  //       if (res) {
  //         message.success(intl.formatMessage({ id: "FORGOTPASSWORD.SEND_LINK_SUCCESS" }), DURATION_MESSAGE);
  //         return history.push("/login");
  //       };
  //       setSubmitting(false);
  //       setStatus(
  //         intl.formatMessage(
  //           { id: "AUTH.VALIDATION.NOT_FOUND" },
  //           { name: values.email }
  //         )
  //       );
  //     });
  //   },
  // });

  const onSubmit = async () => {
    await formRef.current.validateFields();
    setLoading(true)
    const { email } = formRef.current.getFieldsValue()
    const dataSubmit = {
      email: email.trim().toLowerCase()
    }
    const res = await dispatch(actionForgotPassword(dataSubmit));
    setLoading(false);
    if (res) {
      message.success(intl.formatMessage({ id: "FORGOTPASSWORD.SEND_LINK_SUCCESS" }), DURATION_MESSAGE);
      return history.push("/login");
    };
  }

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
          <div className="alert-text row-center fs--16 lh--24">
            <strong>  <h3 className="font-size-h1">
              {intl.formatMessage({ id: "FORGOT_PASSWORD.TITLE" })}
            </h3> </strong>
          </div>
        </div>
        <Form
          name="customForm"
          ref={formRef}
          className="form fv-plugins-bootstrap fv-plugins-framework"
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onSubmit}
        >
          <div className="form-group fv-plugins-icon-container">
            <Form.Item
              type="text"
              name="email"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value.trim() === "") {
                      return Promise.reject(intl.formatMessage({ id: "AUTH.EMAIL_REQUIRE" }));
                    }
                    if (value && !validateEmailWithoutSpecial(value.trim())) {
                      return Promise.reject(intl.formatMessage({ id: "AUTH.EMAIL_WRONG_FORMAT" }));
                    }
                    if (value && !validateEmail(value.trim())) {
                      return Promise.reject(intl.formatMessage({ id: "AUTH.EMAIL_WRONG_FORMAT" }));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input
                maxLength={50}
                className="customInput"
                placeholder="メールアドレスをご入力ください"
                prefix={<img src={`${window.location.origin}/images/email_24px.svg`} alt="" />}
                onKeyPress={e => onEnterPress(e)}
                ref={refEmail}
              />
            </Form.Item>
          </div>
          <div className="form-group fv-plugins-icon-container mt--13 panel-text-forget-password">
            <Link className="text-d-blue forgotpass-btn fs-16 fw-6 text-right db hover-a text-decoration-underline" to="/login">
              {intl.formatMessage({ id: "AUTH.LOGIN.TITLE" })}
            </Link>
          </div>
          <div className="form-group justify-content-between align-items-center " style={{ textAlign: 'center', marginTop: 25 }}>
            <Button
              type='submit'
              className="width100"
              loading={loading}
              title={intl.formatMessage({ id: "FORGOT_PASSWORD.BTN_SUBMIT" })}
              btnColor="#ffffff"
              btnBgColor="#3699FF"
              btnHoverBackground="#187DE4"
              onClick={onSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
