import { Types } from './types'

const initialState = {
    dataLocation: {
        rows: []
    },
}
const locationReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_LOCATION:
            return {
                ...newState,
                dataLocation: action.payload
            }
        default:
            return { ...newState }
    }
}
export default locationReducer
