export const Types = {
  GET_LIST_POSTS: 'GET_LIST_POSTS',
  GET_LIST_CATEGORIES: 'GET_LIST_CATEGORIES',
  SAVE_LIST_SETTING_WIDGETS: 'SAVE_LIST_SETTING_WIDGETS',
  SAVE_LIST_MEDIA: 'SAVE_LIST_MEDIA',
  SAVE_LIST_USERS: 'SAVE_LIST_USERS',
  SAVE_LIST_DUMP_TRASH: 'SAVE_LIST_DUMP_TRASH',
  SAVE_LIST_WASTE_GARBAGE: 'SAVE_LIST_WASTE_GARBAGE',

  SAVE_LOADINGS: 'SAVE_LOADINGS',
  SAVE_LIST_PLASTIC: 'SAVE_LIST_PLASTIC',
  SAVE_DASHBOARD_AGGREGATOR_BUY: 'SAVE_DASHBOARD_AGGREGATOR_BUY',
  SAVE_DASHBOARD_AGGREGATOR_SELL: 'SAVE_DASHBOARD_AGGREGATOR_SELL',

  SAVE_OPEN_HIDE_SIDERBAR: 'SAVE_OPEN_HIDE_SIDERBAR',
}
