import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../_metronic/layout";
import { ROLE_ADMIN } from "../lib/constant";

const ListRole = lazy(() =>
  import("./pages/Role/ListRole")
);

const ListUserAdmin = lazy(() =>
  import("./pages/Admin/ListUserAdmin")
);
const ListVoucher = lazy(() =>
  import('./pages/Voucher/ListVoucher'));
const CompanyList = lazy(() =>
  import("./pages/Company/ListCompany"));

const CategoryList = lazy(() =>
  import("./pages/Category/ListCategory"))
const BrandList = lazy(() =>
  import("./pages/Brand/ListBrand"))
const HolidayList = lazy(() =>
  import("./pages/Holiday/ListHoliday"))
const ProductList = lazy(() =>
  import("./pages/Product/ListProduct"))
const OrderList = lazy(() =>
  import("./pages/Order/ListOrder"))

// const RankList = lazy(() =>
//   import("./pages/Rank/ListRank"))
const TimeWork = lazy(() =>
  import("./pages/TimeWork/TimeWork"))

const FeeSetting = lazy(() =>
  import("./pages/FeeSetting/FeeSetting"))

const UserList = lazy(() =>
  import("./pages/Seller/ListSeller"))
const CommentList = lazy(() =>
  import("./pages/Comment/ListComment"))

const ProfileAdmin = lazy(() =>
  import("./pages/Profile")
);

const OrderDetail = lazy(() =>
  import("./pages/OrderDetail"));

const BillDetail = lazy(() =>
  import("./pages/BillDetail"));

const MultiOrder = lazy(() =>
  import("./pages/MultiOrder"));

const OrderPreSale = lazy(() =>
  import("./pages/OrderPreSale/ListOrderPreSale"));

const OrderNormal = lazy(() =>
  import("./pages/OrderNormal/ListOrderNormal"));

const OrderCombine = lazy(() =>
  import("./pages/OrderCombine/ListOrderCombine"));

const OrderPending = lazy(() =>
  import("./pages/OrderPending/ListOrderPending"));

const AdminDetail = lazy(() =>
  import("./pages/AdminDetail/index"));

const SellerDetail = lazy(() =>
  import("./pages/SellerDetail/index"));

const ProductDetail = lazy(() =>
  import("./pages/ProductDetail/index"));

const ListBill = lazy(() =>
  import("./pages/Bills/ListBill"));

const ListOrderCSV = lazy(() =>
  import("./pages/ExportCSVOrder/ListOrderCSV"));

const ListNews = lazy(() =>
  import("./pages/News/ListNews"));

const ListTypeNews = lazy(() =>
  import("./pages/TypeNews/ListTypeNews"));

export default function BasePage() {
  const { lstPermission } = useSelector(state => ({
    lstPermission: state.authReducer.lstPermission
  }), shallowEqual);

  if (!lstPermission || lstPermission.length === 0) {
    return < LayoutSplashScreen />;
  }

  return (<
    Suspense fallback={< LayoutSplashScreen />} >
    <Switch >
      <Redirect exact from="/"
        to="/order/order-normal" />

      { /* Quản trị hệ thống */}
      <Route path="/admin/role"
        component={ListRole}
        exact /> { /* end */}

      <Route path="/list-admin"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ADMIN_MANAGER) >= 0 ?
            <ListUserAdmin {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={ListUserAdmin}
        exact />
      <Route path="/category"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ADMIN_MANAGER) >= 0 ?
            <CategoryList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={CategoryList}
        exact />
      <Route path="/type-news"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.BRAND_MANAGER) >= 0 ?
            <ListTypeNews {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={ListTypeNews}
        exact />
      <Route path="/brand"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.BRAND_MANAGER) >= 0 ?
            <BrandList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={BrandList}
        exact />
      <Route path="/voucher"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.VOUCHER_MANAGER) >= 0 ?
            <ListVoucher {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={ListVoucher}
        exact />
      <Route path="/company"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.COMPANY_SHIPPING_MANAGER) >= 0 ?
            <CompanyList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={CompanyList}
        exact />
      <Route path="/holiday"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.HOLIDAY_MANAGER) >= 0 ?
            <HolidayList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={HolidayList}
        exact />
      <Route path="/product"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.PRODUCT_MANAGER) >= 0 ?
            <ProductList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={ProductList}
        exact />
      <Route path="/time-work"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.TIMEWORK_MANAGER) >= 0 ?
            <TimeWork {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={TimeWork}
        exact />

      <Route path="/fee-setting"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.TIMEWORK_MANAGER) >= 0 ?
            <FeeSetting {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        exact />


      <Route path="/seller"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.SELLER_MANAGER) >= 0 ?
            <UserList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={UserList}
        exact />
      <Route path="/order/order-all"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <OrderList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderList}
        exact />
      <Route path="/comment"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <CommentList {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={CommentList}
        exact />
      <Route path="/profile"
        component={ProfileAdmin}
        exact />
      <Route path="/detail-order/:id"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <OrderDetail {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/detail-bill/:id"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <BillDetail {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/list-detail-order/:shippingcode"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <MultiOrder {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/order/order-pre-sale"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <OrderPreSale {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderPreSale}
        exact />
      <Route path="/order/order-combine"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <OrderCombine {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        exact />
      <Route path="/order/order-normal"
        component={OrderNormal}
        exact />
      <Route path="/order/order-pending"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <OrderPending {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        exact />
      <Route path="/detail-admin/:id"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <AdminDetail {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/detail-seller/:id"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <SellerDetail {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/product-detail/:id"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <ProductDetail {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/bill"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <ListBill {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/export-order"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <ListOrderCSV {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Route path="/news"
        render={props =>
          lstPermission && lstPermission.indexOf(ROLE_ADMIN.ORDER_MANAGER) >= 0 ?
            <ListNews {...props} /> : <Redirect to={'/order/order-normal'} />
        }
        //component={OrderDetail}
        exact />
      <Redirect from="/login"
        to="/" />
    </Switch>
  </Suspense>
  );
}