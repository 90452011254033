import { fetchApi } from "../../app/lib/api"
import { Types } from './types'
import { checkErrorCode } from '../auth/actions'

/////////////////////////////Get All Plastic////////////////////////////////////////////////
export const actionGetAllPlastic = (payload) => async (dispatch, getState) => {
    try {
        dispatch(actionLoading(true))
        let response = await fetchApi(`/api/config/plastics`, "get")
        if (response.code !== 200) {
            return checkErrorCode(response.code, response.errorCode)
        }
        response = {
            rows: response?.data
        }
        await dispatch(actionSaveListPlastic(response))
        dispatch(actionLoading(false))
        return response
    } catch (error) {
        console.log("getListPlasticError", error)
    }
}

export const actionSaveListPlastic = (payload) => ({
    type: Types.SAVE_LIST_PLASTIC,
    payload,
})
/////////////////////////////End Get All Plastic////////////////////////////////////////////////

export const actionSaveOpenHideSiderBar = (payload) => ({
    type: Types.SAVE_OPEN_HIDE_SIDERBAR,
    payload,
})

/////////////////////////////Get dashboard nhat ky mua////////////////////////////////////////////////
export const actionGetDashboardAggregatorBuy = (payload) => async (dispatch) => {
    try {
        const { startDate, endDate } = payload
        dispatch(actionLoading(true))
        let response = await fetchApi(`/admin/dashboard/false`, "get", { startDate, endDate })
        if (response.code !== 200) {
            return checkErrorCode(response.code, response.errorCode)
        }
        await dispatch(actionSaveDashboardAggregatorBuy(response.data))
        dispatch(actionLoading(false))
        return response.data
    } catch (error) {
        console.log("actionGetDashboardAggregatorBuyError", error)
    }
}

export const actionSaveDashboardAggregatorBuy = (payload) => ({
    type: Types.SAVE_DASHBOARD_AGGREGATOR_BUY,
    payload,
})
/////////////////////////////End dashboard nhat ky mua////////////////////////////////////////////////

/////////////////////////////Get dashboard nhat ky xuat////////////////////////////////////////////////
export const actionGetDashboardAggregatorSell = (payload) => async (dispatch) => {
    try {
        const { startDate, endDate } = payload
        dispatch(actionLoading(true))
        let response = await fetchApi(`/admin/dashboard/true`, "get", { startDate, endDate })
        if (response.code !== 200) {
            return checkErrorCode(response.code, response.errorCode)
        }
        await dispatch(actionSaveDashboardAggregatorSell(response.data))
        dispatch(actionLoading(false))
        return response.data
    } catch (error) {
        console.log("actionGetDashboardAggregatorSellError", error)
    }
}

export const actionSaveDashboardAggregatorSell = (payload) => ({
    type: Types.SAVE_DASHBOARD_AGGREGATOR_SELL,
    payload,
})
/////////////////////////////End dashboard nhat ky xuat////////////////////////////////////////////////

export const actionGetListPosts = (payload) => async (dispatch, getState) => {
    try {

        // const response = await fetchApi("/v1/employees", "get")
        // return console.log("eeeeeeeeeee", response)
        const response = {
            data: [
                { id: "0", Title: "Title1", Author: "Author1", Category: ["cate1", "cate2"], Tags: ["tag1, tag2"], Status: 'Draft', Date: "26/12/2020", },
                { id: "1", Title: "Title2", Author: "Author2", Category: ["cate1", "cate2"], Tags: ["tag1, tag2"], Status: 'Published', Date: "26/12/2020", },
                { id: "2", Title: "Title3", Author: "Author3", Category: ["cate1", "cate2"], Tags: ["tag1, tag2"], Status: 'Published', Date: "26/12/2020", },
            ],
            page: {
                limit: 10,
                total: 32,
            }
        }
        await dispatch(actionSaveListPosts(response))
        // return response.data
    } catch (error) {
        console.log("getListPostError", error)
    }
}

export const actionSaveListPosts = (payload) => ({
    type: Types.GET_LIST_POSTS,
    payload,
})

export const actionGetListCategories = (payload) => async (dispatch, getState) => {
    try {
        const response = {
            data: [
                { id: "0", name: "Name 1", description: "description1", slug: "Name-1", parent_category: 0 },
                { id: "1", name: "Name 2", description: "description2", slug: "Name-2", parent_category: 1 },
                { id: "2", name: "Name 3", description: "description3", slug: "Name-3", parent_category: 2 },
            ],
            page: {
                limit: 10,
                total: 32,
            }
        }
        await dispatch(actionSaveListCategories(response))
        // return response.data
    } catch (error) {
        console.log("getListPostError", error)
    }
}

export const actionSaveListCategories = (payload) => ({
    type: Types.GET_LIST_CATEGORIES,
    payload,
})

export const actionGetSettingWidgets = (payload) => async (dispatch, getState) => {
    try {
        const response = {
            tasks: {
                'task1': {
                    id: 'task1',
                    content: {
                        title: 'Take out the garbage',
                        formFields: [
                            { title: "Title", type: "input", name: "title", value: "" },
                            { title: "Add Images", type: "file_image", name: "file_image", value: null },
                            // { title: "Add Media", type: "file_media", name: "file_media", value: null },
                            {
                                title: "Checkbox", type: "checkbox", name: "checkbox", value: [
                                    { name: "check1", label: 'Display as dropdown', checked: true },
                                    { name: "check2", label: 'Show hierarchy', checked: false },
                                ]
                            },
                            {
                                title: "Categories", type: "select", name: "category", value: 0, options: [
                                    { label: "Display as dropdown", value: 0 },
                                    { label: "Show posts count", value: 1 },
                                ]
                            },
                        ]
                    }
                },
                'task2': {
                    id: 'task2',
                    content: {
                        title: 'Watching TV',
                        formFields: []
                    }
                },
                'task3': {
                    id: 'task3',
                    content: {
                        title: 'Watching TV1',
                        formFields: []
                    }
                },
                'task4': {
                    id: 'task4',
                    content: {
                        title: 'Watching TV2',
                        formFields: []
                    }
                },
            },
            columns: {
                'column1': {
                    id: 'column1',
                    title: '',
                    taskIds: ['task1', 'task2', 'task3', 'task4']
                },
                'column2': {
                    id: 'column2',
                    title: 'In progress',
                    taskIds: []
                },
                'column3': {
                    id: 'column3',
                    title: 'Done',
                    taskIds: []
                }
            },
            // Facilitate reordering of the columns
            columnOrder: ['column1', 'column2', 'column3'],
            taskIncreace: 4,
        }
        await dispatch(actionSaveSettingWidgets(response))
        // return response.data
    } catch (error) {
        console.log("getListPostError", error)
    }
}

export const actionSaveSettingWidgets = (payload) => ({
    type: Types.SAVE_LIST_SETTING_WIDGETS,
    payload,
})

export const actionGetListMedia = (payload) => async (dispatch, getState) => {
    try {
        const response = {
            data: [
                { id: "0", file: { type: "image", name: "Technical Requiremnts Technical Requiremnts.pdf", url: "https://images.unsplash.com/photo-1578762532892-0ffea42b6e6c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80", size: '12MB' }, author: "Andree 1", time: Date.now() },
                { id: "1", file: { type: "video", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 1", time: Date.now() },
                { id: "2", file: { type: "pdf", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 2", time: Date.now() },
                { id: "3", file: { type: "doc", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 3", time: Date.now() },
                { id: "4", file: { type: "xml", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 4", time: Date.now() },
                { id: "5", file: { type: "html", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 5", time: Date.now() },
                { id: "6", file: { type: "csv", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 6", time: Date.now() },
                { id: "7", file: { type: "zip", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 7", time: Date.now() },
                { id: "8", file: { type: "js", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 8", time: Date.now() },
                { id: "8", file: { type: "js", name: "Video 2.mp4", url: "https://www.youtube.com/embed/R8h14x-IuuY", size: '13MB' }, author: "Andree 9", time: Date.now() },
            ],
            page: {
                limit: 10,
                total: 32,
            }
        }
        await dispatch(actionSaveListMedia(response))
        // return response.data
    } catch (error) {
        console.log("getListPostError", error)
    }
}

export const actionGetListUser = (payload) => async (dispatch, getState) => {
    try {
        const response = {
            page: { count: 14, limit: 10 },
            data: [
                { id: '1', name: "Phu Truong", email: "trying@gmail.com", createdAt: "2021-02-25T01:09:06.000Z", status: 'active' },
                { id: '2', name: "Phu Truong", email: "trying@gmail.com", createdAt: "2021-02-25T01:09:06.000Z", status: 'deleted' },
                { id: '3', name: "Phu Truong", email: "trying@gmail.com", createdAt: "2021-02-25T01:09:06.000Z", status: 'active' },
                { id: '4', name: "Phu Truong", email: "trying@gmail.com", createdAt: "2021-02-25T01:09:06.000Z", status: 'active' },
            ],
        }
        await dispatch(actionSaveListUsers(response))
        return response
    } catch (error) {
        console.log("getListPostError", error)
    }
}

export const actionSaveListMedia = (payload) => ({
    type: Types.SAVE_LIST_MEDIA,
    payload,
})


export const actionSaveListUsers = (payload) => ({
    type: Types.SAVE_LIST_USERS,
    payload,
})

export const actionSaveListDumpTrash = (payload) => ({
    type: Types.SAVE_LIST_DUMP_TRASH,
    payload,
})

export const actionSaveListWasteGarbage = (payload) => ({
    type: Types.SAVE_LIST_WASTE_GARBAGE,
    payload,
})

export const actionLoading = (payload) => ({
    type: Types.SAVE_LOADINGS,
    payload,
})


