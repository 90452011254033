/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Popover, Modal } from "antd";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { LanguageSelectorDropdown } from "./LanguageSelectorDropdown";
import { adminLogout } from '../../../../../redux/auth/actions'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

export function UserProfileDropdown({ history, props }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { infoUser } = useSelector(state => ({
    infoUser: state.authReducer.infoUser
  }), shallowEqual)
  const showLogoutConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: "AUTH.LOGIN.TITLE" }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: "AUTH.CONFIRM_LOGOUT" }),
      okText: 'はい',
      okType: 'danger',
      cancelText: 'いいえ',
      onOk() {
        onLogout()
      },
      onCancel() {
        return;
      },
    });
  }
  const onLogout = async () => {
    dispatch(adminLogout());
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><NavLink to="/profile" className='navlink-menu'>{intl.formatMessage({ id: "MY_PROFILE" })}</NavLink></li>
      <li onClick={() => showLogoutConfirm()}>{intl.formatMessage({ id: "LOGOUT" })}</li>
    </ul>
  );

  return (
    <>
      <div className="panel-header d-none-mobile">
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
          trigger="click">
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-dark-50 font-weight-bold font-size-base d-none d-md-inline mr-1 d-sm-inline">
              {(infoUser?.surname || "") + (infoUser?.name || "")}
            </span>{" "}
            <span className="text-muted font-weight-bolder font-size-base d-none d-md-inline mr-3 d-sm-inline">
              {intl.formatMessage({ id: "AUTH.HELLO" })}
            </span>
          </div>
        </Popover>
        {/* <LanguageSelectorDropdown>
        </LanguageSelectorDropdown> */}
      </div>
      <div className="panel-header-mobile" key={`header-2`}>
        <ul className="gx-user-popover">
          <li>{`${(infoUser?.surname || "") + (infoUser?.name || "")}${intl.formatMessage({ id: "AUTH.HELLO" })}`}</li>
          <li className="link-logout"><NavLink to="/profile">{intl.formatMessage({ id: "MY_PROFILE" })}</NavLink></li>
          <li className="link-logout" onClick={() => showLogoutConfirm()}>{intl.formatMessage({ id: "LOGOUT" })}</li>
          {/* <li> <LanguageSelectorDropdown /></li> */}
        </ul>
      </div>
    </>
  );
}
