/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { useIntl } from "react-intl";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ConfrimPassword from "./ConfirmPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  const intl = useIntl();

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              {/* <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10 rowx-center">
                <div className="w--160 h--99 bg-FFFFFF pg--10">
                  <img
                    alt="Logo"
                    className="w--140 h--79"
                    style={{ borderRadius: "26px" }}
                    src={toAbsoluteUrl("images/Logo.svg")}
                  />
                </div>
              </Link> */}
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white text-center">
                  {intl.formatMessage({ id: "AUTH.WELCOME" })}
                </h3>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                {/* <div className="opacity-70 font-weight-bold	text-white">
                  {intl.formatMessage({ id: "AUTH.COPY_RIGHT" })}
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0 panel-for-authen">
              <Switch>
                <ContentRoute path="/login" component={Login} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/confirm-password"
                  component={ConfrimPassword}
                />
                <Redirect from="/auth" exact={true} to="/login" />
                <Redirect to="/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              {/* <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                {intl.formatMessage({ id: "AUTH.COPY_RIGHT" })}
              </div> */}

            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
