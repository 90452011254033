import { Types } from './types'

const initialState = {
    dataBank: {
        rows: []
    },
    listBankOfUser: {
        rows: []
    }
}
const bankReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_BANK:
            return {
                ...newState,
                dataBank: action.payload
            }
        case Types.SAVE_LIST_BANK_OF_USER:
            return {
                ...newState,
                listBankOfUser: action.payload
            }
        default:
            return { ...newState }
    }
}
export default bankReducer
