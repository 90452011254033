/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useLang, setLanguage } from "../../../../i18n";
import { Popover } from "antd";

const languages = [
  {
    lang: "vi",
    name: "Việt Nam",
    flag: toAbsoluteUrl("/media/svg/flags/220-vietnam.svg"),
  },
  {
    lang: "ja",
    name: "Japanese",
    flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg"),
  },
];

export function LanguageSelectorDropdown() {
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);

  const languageMenu = () => (
    <ul className="gx-user-popover">
      {languages.map(language =>
        <li
          key={language.lang}
          className={clsx("navi-item", "navi-item-language", {
            active: language.lang === currentLanguage.lang,
          })}
        >
          <a
            href="#"
            onClick={() => setLanguage(language.lang)}
            className="navi-link"
          >
            <span className="symbol symbol-20 mr-3">
              <img src={language.flag} alt={language.name} />
            </span>
            <span className="navi-text">{language.name}</span>
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
      content={languageMenu()} trigger="click">
      <span className="gx-pointer gx-flex-row gx-align-items-center">
        <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <img
            className="h-25px w-25px rounded"
            src={currentLanguage.flag}
            alt={currentLanguage.name}
          />
        </div>
      </span>
    </Popover>
  );
}
