import { Types } from './types'

const initialState = {
  token: null,
  infoUser: null,
  lstPermission: [],
}
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_INFO_USER:
      return {
        ...state,
        infoUser: action.payload?.user,
        lstPermission: action.payload?.lstPermission || [],
      }
    case Types.SAVE_LOGIN:
      return {
        ...state,
        token: action.payload.user
      }
    case Types.UPDATE_PROFILE_USER:
      return {
        ...state,
        infoUser: {...action.payload.infoUser, ...action.payload.user }
      }
    default:
      return { ...state }
  }
}
export default homeReducer
