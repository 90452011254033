import { fetchApi } from "../../app/lib/api"
import { Types } from './types'
import { message } from 'antd';
import { actionLoading } from '../home/actions';
import { DURATION_MESSAGE } from "../../lib/constant";

import Vi from '../../_metronic/i18n/messages/vi.json';

export const actionLogout = () => async () => {
    try {
        localStorage.removeItem("token")
        window.location.assign("/login")
        return;
    } catch (error) {
        console.log("loginError", error)
    }
}

export const actionLogin = (payload) => async (dispatch, getState) => {
    try {
        const response = await fetchApi("/admin/login", "post", payload)

        if (response.code !== 200) {
            return checkErrorCode(response?.code, response?.errorCode)
        }
        const token = response?.data?.token || null
        localStorage.setItem("token", token);
        const lstPermission = [];
        response?.data?.listPermission &&  response?.data?.listPermission.forEach(element => {
          if(!element.permission) {
            return;
          }
          if(lstPermission.indexOf(element.permission.code) < 0) {
            lstPermission.push(element.permission.code);
          }
        });
        return dispatch(actionSaveInfoUser({
          user: response?.data?.user,
          lstPermission: lstPermission
        }));
    } catch (error) {
        console.log("logoutError", error)
    }
}

export const actionLoginByToken = () => async (dispatch) => {
    try {
        const response = await fetchApi(`/admin/profile`, "get")
        if (response?.code !== 200) {
            return checkErrorCode(response?.code, response?.message)
        }
        // const token = response?.data?.token || null
        // localStorage.setItem("token", token)
        const lstPermission = [];
        response?.data?.listPermission &&  response?.data?.listPermission.forEach(element => {
          if(!element.permission) {
            return;
          }
          if(lstPermission.indexOf(element.permission.code) < 0) {
            lstPermission.push(element.permission.code);
          }
        });
        dispatch(actionSaveInfoUser({
          user: response?.data?.user,
          lstPermission: lstPermission
        }))
        return response?.data?.user
    } catch (error) {
        console.log("loginError", error)
    }
}

export const adminLogout = () => async (dispatch) => {
  try {
      dispatch(actionLoading(true));
      const response = await fetchApi("/admin/admin-manager/logout", "post", {});
      dispatch(actionLoading(false));
      if (response?.code !== 200) {
          return checkErrorCode(response?.code, response?.message)
      }
      await localStorage.removeItem("token");
      dispatch(actionSaveInfoUser(null));
      window.location.reload("/login");
  } catch (error) {
      console.log("loginError", error)
  }
}

export const actionSaveInfoUser = (payload) => ({
    type: Types.SAVE_INFO_USER,
    payload,
})


export const actionForgotPassword = (payload) => async (dispatch, getState) => {
  try {
      dispatch(actionLoading(true))
      const response = await fetchApi("/admin/admin-manager/forgot-password", "post", payload);
      dispatch(actionLoading(false))
      if (response.code !== 200) {
          return checkErrorCode(response?.code, response?.errorCode)
      }
      return response?.data;
  } catch (error) {
      console.log("logoutError", error)
  }
}

export const actionResetForgotPassword = (payload) => async (dispatch, getState) => {
  try {
      dispatch(actionLoading(true))
      const response = await fetchApi("/admin/admin-manager/reset-password-from-link", "post", payload);
      dispatch(actionLoading(false))
      if (response.code !== 200) {
          return checkErrorCode(response?.code, response?.errorCode)
      }
      return response?.data;
  } catch (error) {
      console.log("logoutError", error)
  }
}

export const actionCheckValidLink = (payload) => async (dispatch, getState) => {
  try {
      dispatch(actionLoading(true))
      const response = await fetchApi("/admin/admin-manager/valid-link-reset-password", "post", payload);
      dispatch(actionLoading(false))
      if (response.code !== 200) {
          return checkErrorCode(response?.code, response?.errorCode)
      }
      return response?.data;
  } catch (error) {
      console.log("logoutError", error)
  }
}

export const checkErrorCode = async (code, errorMessage) => {
    switch (code) {
        case 400: {
            message.error(`${(errorMessage && errorMessage.startsWith('ERR_')) ? Vi[errorMessage] : 'エラーが発生されました'}`, DURATION_MESSAGE)
            break;
        }
        case 401: {
            message.error("Authen token is invalid!", DURATION_MESSAGE)
            localStorage.removeItem("token")
            window.location.assign("/login")
            break;
        }
        default:
            break;
    }
}