import { Types } from './types'

const initialState = {
    listPosts: null,
    listCategories: null,
    recordEdit: {},
    listSettingWidgets: null,
    listMedia: null,
    dataUsers: {
        page: {},
        data: [],
    },
    dataConsumer: {
        page: { count: 14, limit: 10 },
        data: [
            { id: '1', name: "Phu Truong", phone: "04113", createdAt: "2021-02-25T01:09:06.000Z" },
            { id: '2', name: "Phu Truong", phone: "04113", createdAt: "2021-02-25T01:09:06.000Z" },
            { id: '3', name: "Phu Truong", phone: "04113", createdAt: "2021-02-25T01:09:06.000Z" },
            { id: '4', name: "Phu Truong", phone: "04113", createdAt: "2021-02-25T01:09:06.000Z" },
        ],
    },
    dataDumpTrash: {
        page: { count: 14, limit: 10 },
        data: [
            { id: '1', userId: "Phu Truong", plasticType: "PE", dumpingDate: "2021-02-25T01:09:06.000Z", weight: "70kg", method: "Đổ ra đường" },
            { id: '2', userId: "Phu Truong", plasticType: "PET", dumpingDate: "2021-02-25T01:09:06.000Z", weight: "70kg", method: "Đốt" },
            { id: '3', userId: "Phu Truong", plasticType: "PP", dumpingDate: "2021-02-25T01:09:06.000Z", weight: "70kg", method: "Đổ ra đường" },
            { id: '4', userId: "Phu Truong", plasticType: "PVC", dumpingDate: "2021-02-25T01:09:06.000Z", weight: "70kg", method: "Đổ ra đường" },
        ],
    },
    dataWasteGarbage: {
        page: { count: 14, limit: 10 },
        data: [
            { id: '1', userId: "Phu Truong", vehicle: "Xe tải", collectionDate: "2021-02-25T01:09:06.000Z", pet: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pe: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pp: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, ps: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pvc: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaKhac: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaDaLop: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, },
            { id: '2', userId: "Phu Truong", vehicle: "Xe con", collectionDate: "2021-02-25T01:09:06.000Z", pet: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pe: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pp: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, ps: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pvc: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaKhac: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaDaLop: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, },
            { id: '3', userId: "Phu Truong", vehicle: "Xe tải", collectionDate: "2021-02-25T01:09:06.000Z", pet: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pe: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pp: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, ps: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pvc: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaKhac: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaDaLop: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, },
            { id: '4', userId: "Phu Truong", vehicle: "Xe tải", collectionDate: "2021-02-25T01:09:06.000Z", pet: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pe: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pp: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, ps: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, pvc: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaKhac: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, nhuaDaLop: { pricePurchase: "20$", priceSale: "20$", weight: "10kg" }, },
        ],
    },

    spin: false,
    dataPlastic: {
        rows: []
    },
    dataDashboardAggregatorBuy: {},
    dataDashboardAggregatorSell: {},

    isShowSiderbar: true,
}
const homeReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_DASHBOARD_AGGREGATOR_BUY:
            return {
                ...newState,
                dataDashboardAggregatorBuy: action.payload
            }
        case Types.SAVE_DASHBOARD_AGGREGATOR_SELL:
            return {
                ...newState,
                dataDashboardAggregatorSell: action.payload
            }
        case Types.SAVE_LIST_PLASTIC:
            return {
                ...newState,
                dataPlastic: action.payload
            }
        case Types.SAVE_LOADINGS:
            return {
                ...newState,
                spin: action.payload
            }
        case Types.SAVE_LIST_USERS:
            return {
                ...newState,
                dataUsers: action.payload
            }
        case Types.SAVE_LIST_MEDIA:
            return {
                ...newState,
                listMedia: action.payload
            }
        case Types.SAVE_LIST_SETTING_WIDGETS:
            return {
                ...newState,
                listSettingWidgets: {
                    ...state.listSettingWidgets,
                    ...action.payload
                }
            }
        case Types.GET_LIST_POSTS:
            return {
                ...newState,
                listPosts: action.payload
            }
        case Types.GET_LIST_CATEGORIES:
            return {
                ...newState,
                listCategories: action.payload
            }

        case Types.SAVE_OPEN_HIDE_SIDERBAR:
            return {
                ...newState,
                isShowSiderbar: action.payload
            }
        default:
            return { ...newState }
    }
}
export default homeReducer
