import { Types } from './types'

const initialState = {
    dataRank: {
        filter: {},
        rows: []
    },
    currentRecord: null
}
const rankReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_RANK:
            return {
                ...newState,
                dataRank: action.payload
            }
        case Types.SET_RECORD:
            return {
                ...newState,
                currentRecord: action.payload
            }
        default:
            return {...newState }
    }
}
export default rankReducer