import { Types } from './types'

const initialState = {
    dataProduct: {
        filter: {},
        rows: []
    },
    dataProductCreate: {
        filter: {},
        rows: []
    },
    detailProduct: {
    },
    lstProductAdd: []
}
const productReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_PRODUCT:
            return {
                ...newState,
                dataProduct: action.payload
            }
        case Types.SAVE_LIST_PRODUCT_CREATE:
            return {
                ...newState,
                dataProductCreate: action.payload
            }
        case Types.UPDATE_LIST_PRODUCT_ADD:
            return {
                ...newState,
                lstProductAdd: action.payload
            }
        case Types.SAVE_DETAIL_PRODUCT:
            return {
                ...newState,
                detailProduct: action.payload
            }
        default:
            return { ...newState }
    }
}
export default productReducer
