import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import * as auth from "../_redux/authRedux";
import { useDispatch } from "react-redux";
import { actionLogin } from '../../../../redux/auth/actions'
import { Input, Form } from 'antd';
import Button from '../../../../components/CustomButton'
import { validateEmailWithoutSpecial } from "../../../lib/common";

const formRef = React.createRef()

function Login(props) {

  const { history } = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSubmit()
    }
  }

  const onSubmit = async () => {
    await formRef.current.validateFields();
    setLoading(true)
    const { email, password } = formRef.current.getFieldsValue()
    const dataSubmit = {
      email: email.trim().toLowerCase(),
      password
    }
    const res = await dispatch(actionLogin(dataSubmit))
    if (res) {
      return history.push("/order/order-normal")
    }
    setLoading(false)
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
        <div className="alert-text row-center fs--16 lh--24">
          <strong>  <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3> </strong>
        </div>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Form
        name="customForm"
        ref={formRef}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        <div className="form-group fv-plugins-icon-container">
          <Form.Item
            type="text"
            name="email"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === "") {
                    return Promise.reject(intl.formatMessage({ id: "AUTH.EMAIL_REQUIRE" }))
                  } else if (value && !validateEmailWithoutSpecial(value.trim())) {
                    return Promise.reject(intl.formatMessage({ id: "AUTH.EMAIL_WRONG_FORMAT" }))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input
              maxLength={50}
              className="customInput"
              placeholder={intl.formatMessage({ id: "LOGIN.EMAIL_PLACEHOLDER" })}
              prefix={<img src="images/email_24px.svg" alt="" />}
              onKeyPress={e => onEnterPress(e)}
            />
          </Form.Item>
        </div>
        <div className="form-group fv-plugins-icon-container mt--13 panel-password">
          <Form.Item
            name="password"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === "") {
                    return Promise.reject(intl.formatMessage({ id: "AUTH.REQUIED_PASSWORD" }))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <Input
              maxLength={30}
              type="password"
              className="customInput"
              placeholder={intl.formatMessage({ id: "LOGIN.PASSWORD_PLACEHOLDER" })}
              prefix={<img src="images/lock_24px 2.svg" className="ml--2 mr--2" alt="" />}
              onKeyPress={e => onEnterPress(e)}
            />
          </Form.Item>
        </div>
        <div className="form-group fv-plugins-icon-container mt--13 panel-text-forget-password">
          <Link className="text-d-blue forgotpass-btn fs-16 fw-6 text-right db hover-a text-decoration-underline" to="/auth/forgot-password">
            {intl.formatMessage({ id: "LOGIN.FORGET_PASSWORD" })}
          </Link>
        </div>
        <div className="form-group justify-content-between align-items-center " style={{ textAlign: 'center', marginTop: 25 }}>
          <Button
            type='submit'
            className="width100"
            loading={loading}
            title={intl.formatMessage({ id: "LOGIN.BTN_LOGIN" })}
            btnColor="#ffffff"
            btnBgColor="#3699FF"
            btnHoverBackground="#187DE4"
            onClick={onSubmit}
          />
        </div>
      </Form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
